<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Page title -->
        <div class="my-5">
          <h3>Мой профиль</h3>
          <hr>
        </div>
        <!-- Form START -->
        <form class="file-upload">
          <div class="row mb-5 gx-5">



            <!-- Contact detail -->
            <div class="col-xxl-8 mb-5 mb-xxl-0">
              <div class="bg-secondary-soft px-4 py-5 rounded">
                <div class="row g-3">
                  <h4 class="mb-4 mt-0">Основная информация</h4>
                  <!-- Last name -->
                  <div class="col-md-4">
                    <label class="form-label">Фамилия *</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Last name" v-model="studentInfo.last_name" disabled>
                  </div>
                  <!-- First Name -->
                  <div class="col-md-4">
                    <label class="form-label">Имя *</label>
                    <input type="text" class="form-control" placeholder="" aria-label="First name" v-model="studentInfo.first_name" disabled>
                  </div>
                  <!-- Middle name -->
                  <div class="col-md-4">
                    <label class="form-label">Отчество *</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Middle name" v-model="studentInfo.middle_name" disabled>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">День рождения</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Birth date" v-model="studentInfo.birth_date" disabled>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Место рождения</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Birth place" v-model="studentInfo.birth_place" disabled>
                  </div>
                  <!-- Barcode -->
                  <div class="col-md-6">
                    <label class="form-label">Баркод</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Barcode" v-model="studentInfo.barcode" disabled>
                  </div>
                  <!-- Education Program -->
                  <div class="col-md-6">
                    <label class="form-label">ОП</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Education program" v-model="studentInfo.education_speciality_name" disabled>
                  </div>

                  <div class="col-md-6">
                    <label class="form-label">Уровень обучения</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Study level" v-model="studentInfo.study_level" disabled>
                  </div>

                  <div class="col-md-6">
                    <label class="form-label">Курс</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Study course" v-model="studentInfo.study_course" disabled>
                  </div>
                  <div class="col-md-6">
                    <label class="form-label">Источник финансирования</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Financing source" v-model="studentInfo.full_name" disabled>
                  </div>

                  <div class="col-md-6">
                    <label class="form-label">ИИН</label>
                    <input type="text" class="form-control" placeholder="" aria-label="IIN" v-model="studentInfo.iin" disabled>
                  </div>

                  <div class="col-md-4">
                    <label class="form-label">Номер документа</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Doc number" v-model="studentInfo.doc_number" disabled>
                  </div>

                  <div class="col-md-4">
                    <label class="form-label">Орган выдачи</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Doc given org" v-model="studentInfo.doc_given_org" disabled>
                  </div>
                  <div class="col-md-4">
                    <label class="form-label">Дата выдачи</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Doc given date" v-model="studentInfo.doc_given_date" disabled>
                  </div>
                  <!-- Phone number -->
                  <div class="col-md-6">
                    <label class="form-label">Телефон</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Phone number" v-model="studentInfo.phone" disabled>
                  </div>
                  <!-- Email -->
                  <div class="col-md-6">
                    <label for="inputEmail4" class="form-label">Email *</label>
                    <input type="email" class="form-control" id="inputEmail4" v-model="studentInfo.email" disabled>
                  </div>
                </div> <!-- Row END -->
              </div>
            </div>

            <!-- Upload profile -->
            <div class="col-xxl-4">
              <div class="bg-secondary-soft px-4 py-5 rounded">
                <div class="row g-3">
                  <h4 class="mb-4 mt-0">Фото</h4>
                  <div class="text-center" >
                    <!-- Image upload -->
                    <div class="square position-relative display-2 mb-3">
                      <div v-if="studentInfo.photo">
                        <img width="250" height="250" class="position-absolute top-50 start-50 translate-middle text-secondary"
                             :src="'data:image/png;base64,'+studentInfo.photo"/>
                      </div>
                      <div v-else>
                        <i class="fas fa-fw fa-user position-absolute top-50 start-50 translate-middle text-secondary"></i>
                      </div>



                    </div>
                    <!-- Button -->
<!--                    <input type="file" id="customFile" name="file" hidden="">-->
<!--                    <label class="btn btn-success-soft btn-block" for="customFile">Загрузить</label>-->
<!--                    <button type="button" class="btn btn-danger-soft">Удалить</button>-->
                    <!-- Content -->
<!--                    <p class="text-muted mt-3 mb-0"><span class="me-1">Примечание:</span>Размер должен быть минимум 300х300</p>-->
                  </div>
                </div>
              </div>
            </div>

          </div> <!-- Row END -->

          <!-- Parent info -->
          <div class="row mb-5 gx-5">

            <div class="col-xxl-12 mb-5 mb-xxl-0">
              <div class="bg-secondary-soft px-4 py-5 rounded">
                <div class="row g-3">
                  <h4 class="mb-4 mt-0">Информация о родителях</h4>

                  <div class="col-md-4">
                    <label class="form-label">Фамилия </label>
                    <input type="text" class="form-control" placeholder="" aria-label="Parent lastname" v-model="studentInfo.parent_last_name" disabled>
                  </div>
                  <div class="col-md-4">
                    <label class="form-label">Имя </label>
                    <input type="text" class="form-control" placeholder="" aria-label="Parent firstname" v-model="studentInfo.parent_first_name" disabled>
                  </div>
                  <div class="col-md-4">
                    <label class="form-label">Отчество </label>
                    <input type="text" class="form-control" placeholder="" aria-label="Parent middle" v-model="studentInfo.parent_middle_name" disabled>
                  </div>

                  <div class="col-md-4">
                    <label class="form-label">Номер документа</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Doc number" v-model="studentInfo.parent_doc_number" disabled>
                  </div>

                  <div class="col-md-4">
                    <label class="form-label">Орган выдачи</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Doc given org" v-model="studentInfo.parent_doc_given_org" disabled>
                  </div>
                  <div class="col-md-4">
                    <label class="form-label">Дата выдачи</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Doc given date" v-model="studentInfo.parent_doc_given_date" disabled>
                  </div>
                  <!-- Phone number -->
                  <div class="col-md-6">
                    <label class="form-label">Телефон</label>
                    <input type="text" class="form-control" placeholder="" aria-label="Phone number" v-model="studentInfo.parent_phone" disabled>
                  </div>


                </div> <!-- Row END -->
              </div>
            </div>

            <!-- change password -->
            <div class="col-xxl-6" style="display: none">
              <div class="bg-secondary-soft px-4 py-5 rounded">
                <div class="row g-3">
                  <h4 class="my-4">Change Password</h4>
                  <!-- Old password -->
                  <div class="col-md-6">
                    <label for="exampleInputPassword1" class="form-label">Old password *</label>
                    <input type="password" class="form-control" id="exampleInputPassword1">
                  </div>
                  <!-- New password -->
                  <div class="col-md-6">
                    <label for="exampleInputPassword2" class="form-label">New password *</label>
                    <input type="password" class="form-control" id="exampleInputPassword2">
                  </div>
                  <!-- Confirm password -->
                  <div class="col-md-12">
                    <label for="exampleInputPassword3" class="form-label">Confirm Password *</label>
                    <input type="password" class="form-control" id="exampleInputPassword3">
                  </div>
                </div>
              </div>
            </div>
          </div> <!-- Row END -->


          <div class="row mb-5 gx-5">
            <!-- doc orders -->
            <div class="col-xxl-12">
              <div class="bg-secondary-soft px-4 py-5 rounded">
                <div class="row g-3">
                  <h4 class="my-4">Приказы</h4>

                  <div class="col-md-4" v-for="(doc_order, index) in studentInfo.doc_orders" :key="index">
                    <Card style="width: 25em; height: 25em">
                      <template #header>
                       <p style="padding-top: 16px; padding-left: 16px;">
                         № {{ index+1 }}
                       </p>
                      </template>
                      <template #title>
                        {{ doc_order.order_number}} - {{ convertTimestampToDate(doc_order.doc_order_date)}}
                      </template>
                      <template #subtitle>
                        {{ doc_order.doc_order_type_id }}
                      </template>
                      <template #content>
                        <p>{{ doc_order.order_text_ru }}</p>
                      </template>
                    </Card>
                  </div>

<!--                  <div class="col-md-1">-->
<!--                    <label class="form-label">{{index+1}}</label>-->
<!--                  </div>-->
<!--                  <div class="col-md-2">-->
<!--                    <label class="form-label">{{doc_order.doc_order_date}}</label>-->
<!--                  </div>-->
<!--                  <div class="col-md-3">-->
<!--                    <label class="form-label">{{doc_order.order_number}}</label>-->
<!--                  </div>-->
<!--                  <div class="col-md-3">-->
<!--                    <label class="form-label">{{doc_order.doc_order_type_id }}</label>-->
<!--                  </div>-->
<!--                  <div class="col-md-3">-->
<!--                    <label class="form-label">{{doc_order.order_text_ru }}</label>-->
<!--                  </div>-->

                </div>
              </div>
            </div>


          </div> <!-- Row END -->
          <!-- button -->
<!--          <div class="gap-3 d-md-flex justify-content-md-end text-center">-->
<!--            <button type="button" class="btn btn-danger btn-lg">Delete profile</button>-->
<!--            <button type="button" class="btn btn-primary btn-lg">Update profile</button>-->
<!--          </div>-->
        </form> <!-- Form END -->
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from "@/services/http.service";
import {convertDateInputToTimestamp, convertTimestampToDate} from "@/utils/helpers/date.helpers"
export default {
  name: "MyProfile",
  data() {
    return {
      student_id: +this.$route.query.student_id || null,
      studentInfo: {}
    }
  },
  computed: {

  },
  methods: {
    convertTimestampToDate,

    async getStudentInfo() {
      let url = `student_data/student-data/get-student-info`
      let urlParam = ''
      if (this.student_id != null) {
        urlParam = '?student_id='+this.student_id
      }
      try {
        const {status, data} = await httpClient.get(url + urlParam)
        if (status === 200) {
          this.studentInfo = data
          console.log('getStudentInfo', data)
          return true
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
      return false
    },
  },

  async mounted() {
    await this.getStudentInfo()
  }
}
</script>

<style scoped>
.bg-secondary-soft {
  background-color: rgba(208, 212, 217, 0.1) !important;
}
.rounded {
  border-radius: 5px !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.file-upload .square {
  height: 250px;
  width: 250px;
  margin: auto;
  vertical-align: middle;
  border: 1px solid #e5dfe4;
  background-color: #fff;
  border-radius: 5px;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(208, 212, 217, 0.5) !important;
}
.btn-success-soft {
  color: #28a745;
  background-color: rgba(40, 167, 69, 0.1);
}
.btn-danger-soft {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #29292e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5dfe4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
</style>